import React from "react"
import { Tabs, Tab, Container, Row, Col, Button } from "react-bootstrap"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Banner from "../components/sections/banner"
import Blog from "../components/sections/blogs"
import InformationSection from "../components/sections/informational-section";
import FeaturedCaseStudy from "../components/sections/case-study/featured-case-study";
import OtherMIESolutions from "../components/sections/hubs/other-MIE-hub";

import ourOwnFibreIcon from "../images/rne/info/ownfibre_icon.svg";
import connectedLivingIcon from "../images/rne/info/connected_living_icon.svg";
import qualityIcon from "../images/rne/info/quality_icon.svg";
import readyOndayIcon from "../images/rne/info/ready_onday_icon.svg";

import RetailerTab from "../components/tabs/retailer";
import EnhanceTab from "../components/tabs/enhancing-usability-spaces";

import buildToRentIcon from "../images/other-mie/buildtorent.png";
import hotelIcon from "../images/other-mie/hotels.png";
import publicWifiIcon from "../images/other-mie/public_wifi.png";
import studAccoIcon from "../images/other-mie/student_accom.png";

import wickhamTerraceIcon from "../images/rne/wickham_terrace.svg";

import bannerImage from "../images/box-headers/residential_mdu.jpg";

import bgImg from "../images/headers/subheader_blue.svg";

import {getCategoryId} from "../utils/categories";
const categoryId = getCategoryId("Residential Development");

const ManagedInternetEnvironments = () => (
  <Layout title="Residential Fibre Network">
    <SEO title="Residential Development - Wholesale Internet - VostroNet">
      <meta name="description" content="VostroNet is an expert in delivering high speed wholesale internet. We provide a Fibre-to-the-Premises network for Residential MDU Developments." />
    </SEO>
    <Banner subpage bgImg={bgImg} data={{
      title: {
        name: (<>
          {"Residential"}<br/>{"Fibre Internet"}
        </>),
      },
      subtitles: [
        {
          name: `A smarter home network`,
          className: "fw-400",
        },
        {
          name: "Established wholesale Fibre-to-the-Premises provider throughout the Asia-Pacific region. We are dedicated to offering residents a premium service.",
          className: "banner-text-small",
        },
      ],
      img: bannerImage,
    }}
    hideScrollButton
    customButtons={(<>
      <Container>
        <Row>
          <Col xs="auto">
            <Button variant="green" className="btn-banner mb-3" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-datasheet-residential.pdf">
              <i className="fas fa-file-pdf mr-2" />
              {"Residential Datasheet"}
            </Button>
          </Col>
          <Col xs="auto">
            <Button variant="outline-dark" className="btn-banner-secondary" rel="noopener" target="_blank" href="https://cdn.vostro.cloud/content/vostronet-solutions-residential.pdf">
              <i className="fas fa-binoculars mr-2" />
              {"Quick overview"}
            </Button>
          </Col>
        </Row>
      </Container>
    </>)}/>
    <div className="general py-5">
      <Container>
        <Row>
          <Col>
            <div className="title mb-3">
              {"Connecting residents"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="desc my-4">
              {"Our residential network solution utilises 10Gbps fibre infrastructure to provide a seamless user experience, tailored to a multi-dwelling unit environment. We operate an extensive fibre network in metropolitan centres in the Asia-Pacific."}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <InformationSection data={{
      title: "First-Class Infrastructure",
      subtitle: "We are dedicated to delivering a reliable, fast and expanding FTTx network, underpinned by world-class technology. VostroNet is Australia's largest private Nokia FTTx carrier.",
      subData: [
        {
          title: "Our own fibre",
          subtitle: "By owning and operating our own fibre from the data centre to the home, we can ensure performance and quality is maintained at a higher level than others.",
          img: ourOwnFibreIcon,
        },
        {
          title: "Quality",
          subtitle: "We invest resources to tailor our design to suit the project. We use the best equipment to minimise downtime and maximise performance.",
          img: qualityIcon,
        },
        {
          title: "Connected Living",
          subtitle: "Create a true vertical community with the assistance of Wi-Fi coverage everywhere for residents. We are the experts in deploying and operating Wi-Fi.",
          img: connectedLivingIcon,
        },
        {
          title: "Ready on day one guarantee",
          subtitle: "VostroNet guarantees that the internet will be available at the agreed move-in date. Residents shouldn’t wait to receive fast internet.",
          img: readyOndayIcon,
        },
      ],
      col: 6,
    }} />

    <Container className="tab-section py-5">
      <Row>
        <Col>
          <Tabs defaultActiveKey="enhance" id="RFN-tab-section">
            <Tab eventKey="enhance" title="Enhancing the usability of spaces">
              <EnhanceTab />
            </Tab>
            <Tab eventKey="retailer" title="Retailer Advantage">
              <RetailerTab />
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>

    <FeaturedCaseStudy data={{
      link: "https://cdn.vostro.cloud/content/casestudy-residential-utopia.pdf",
      title: "Featured case studies",
      desc: "A dedicated high-speed residential fibre solution to 275 Wickham Street",
      img: wickhamTerraceIcon,
      sideNote: "VostroNet deploys a future-proof fibre mixed-use network solution to the Utopia Space development in Fortitude Valley, Brisbane.",
    }} />
    <Blog category={categoryId} />
    <OtherMIESolutions data={[
      {
        label: "Build-to-Rent",
        link: "/build-to-rent/",
        img: buildToRentIcon,
      },
      {
        label: "Hotels",
        link: "/hotel/",
        img: hotelIcon,
      },
      {
        label: "Public Wi-Fi",
        link: "/public-wifi/",
        img: publicWifiIcon,
      },
      {
        label: "Student Accommodation",
        link: "/student-accommodation/",
        img: studAccoIcon,
      },
    ]} />
  </Layout>
)

export default ManagedInternetEnvironments;
