import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import competitiveIcon from "../../images/rne/tabs/competitive_plan.svg";
import highspeed_soln from "../../images/dark-fibre/info/highspeed_soln.svg";

export default function Retailer() {
	return (
		<div className="tab-retailer">
			<Container fluid className="p-4">
				<Row>
					<Col>
						<div className="tab-main-title">
							{"Residents can choose internet speeds of up to 1Gbps on our FTTx network through one of our retail service providers."}
						</div>
					</Col>
				</Row>
				<Row className="my-4">
					<Col>
						<img className="tab-img mr-3" src={highspeed_soln} alt="competitive plan icon" />
						<div className="tab-title">
							{"High-speed retail plans"}
						</div>
					</Col>
					<Col xs={12} lg={8}>
						<div className="tab-desc">
							{"Residents have access to high-speed internet with speeds of up to 1Gbps."}
						</div>
					</Col>
				</Row>
				<Row className="my-4">
					<Col>
						<img className="tab-img mr-3" src={competitiveIcon} alt="competitive plan icon" />
						<div className="tab-title">
							{"Competitive plans"}
						</div>
					</Col>
					<Col xs={12} lg={8}>
						<div className="tab-desc">
							{"VostroNet frequently market tests our wholesale plans to ensure users are receiving the best value for money."}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}